<template>
	<v-app>
		<v-navigation-drawer v-if="$vuetify.breakpoint.mdAndDown" v-model="drawer" app>
			<div class="px-4 pt-8 pb-4 mb-4 text-center">
				<router-link :to="homeRoute">
					<img src="/assets/images/Culture-Program-single.png" alt="Brainlab Culture Program" height="120" />
				</router-link>
			</div>

			<v-list-item
				v-for="(item, i) in menu"
				:key="i"
				class="text-none"
				:to="item.type === 'page' ? item.route : undefined"
				:href="item.type === 'url' ? item.value : undefined"
				:target="item.target !== '_self' ? item.target : undefined">
				<v-list-item-content>
					<v-list-item-title> {{ item.name }} </v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider />
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="text-right">
						<router-link :to="enPath" class="text--primary text-decoration-none body-2">EN</router-link>
						<span class="body-2" style="position: relative; top: -1px">&nbsp;|&nbsp;</span>
						<router-link :to="dePath" class="text--primary text-decoration-none body-2">DE</router-link>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-navigation-drawer>

		<v-app-bar
			app
			prominent
			color="white"
			:class="$vuetify.breakpoint.mdAndUp ? 'main-app-bar' : 'main-app-bar'"
			elevate-on-scroll
			:height="$vuetify.breakpoint.smAndDown ? 60 : 120">
			<!-- Desktop -->
			<v-container v-if="$vuetify.breakpoint.mdAndUp" fill-height class="d-flex flex-nowrap">
				<div class="brand-image pt-4 pb-2">
					<router-link :to="homeRoute">
						<img src="/assets/images/Culture-Program-single.png" alt="Brainlab Culture Program" />
					</router-link>
				</div>
				<div class="d-flex flex-nowrap navigation">
					<v-tabs align-with-title height="30" hide-slider :optional="true" active-class="font-weight-bold text--primary">
						<v-tab
							v-for="(item, i) in menu"
							:key="i"
							class="text-none text-body-1"
							:to="item.type === 'page' ? item.route : undefined"
							:href="item.type === 'url' ? item.value : undefined"
							:target="item.target !== '_self' ? item.target : undefined">
							{{ item.name }}
						</v-tab>
					</v-tabs>

					<div class="languages" v-if="$route.name !== 'Vernissage'">
						<router-link :to="enPath" class="text--primary text-decoration-none">EN</router-link>
						<span style="position: relative; top: -1px">&nbsp;|&nbsp;</span>
						<router-link :to="dePath" class="text--primary text-decoration-none">DE</router-link>
					</div>
				</div>
			</v-container>

			<!-- Mobile -->
			<template v-else>
				<v-app-bar-nav-icon v-if="$route.name !== 'Vernissage'" @click="drawer = !drawer"></v-app-bar-nav-icon>
				<router-link :to="homeRoute">
					<img src="/assets/images/Culture-Program-single.png" alt="Brainlab Culture Program" height="50" />
				</router-link>
				<v-spacer />
				<v-btn v-if="$route.name === 'Vernissage'" icon @click="toggleScanner()">
					<v-icon>mdi-qrcode-scan</v-icon>
				</v-btn>
			</template>
		</v-app-bar>

		<v-main>
			<router-view />
			<Scanner v-if="$route.name === 'Vernissage'" :enabled="scanner" />
		</v-main>
		<v-footer dark color="black" class="py-8">
			<v-container>
				<v-row no-gutters>
					<v-col cols="12" md="3" align-self="end">
						{{ $t('Footer.1.company') }}<br />
						{{ $t('Footer.1.street') }}<br />
						{{ $t('Footer.1.city') }}
					</v-col>
					<v-col cols="12" md="4" align-self="end">
						{{ $t('Footer.2.phone.label') }}: {{ $t('Footer.2.phone.number') }}<br />
						{{ $t('Footer.2.email.label') }}:
						<a :href="'mailto:' + $t('Footer.2.email.address')" class="white--text">{{ $t('Footer.2.email.address') }}</a>
					</v-col>
					<v-col cols="12" md="3" align-self="end">
						<a :href="$t('Footer.3.imprint.href')" class="white--text">{{ $t('Footer.3.imprint.label') }}</a
						><br />
						<a :href="$t('Footer.3.privacy.href')" class="white--text">{{ $t('Footer.3.privacy.label') }}</a>
					</v-col>
					<v-col cols="12" md="2" align-self="end" class="text-right"> &copy; Brainlab AG </v-col>
				</v-row>
			</v-container>
		</v-footer>
	</v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'App',
	components: {
		Scanner: () => import('@/components/Scanner'),
	},
	computed: {
		...mapState(['language', 'scanner']),
		...mapState('pageBuilder', ['menu', 'translations']),
		homeRoute() {
			return this.language === 'de' ? '/' : `/${this.language}`
		},
		enPath() {
			return this.translations.en || '/en'
		},
		dePath() {
			return this.translations.de || '/'
		},
	},
	data: () => ({
		drawer: false,
	}),
	methods: {
		toggleScanner() {
			this.$store.state.scanner = true
		},
	},
}
</script>
<style>
.main-app-bar .v-toolbar__content {
	min-height: 100px;
}
.brand-image {
	display: block;
	width: 30%;
	max-width: 300px;
	height: 100%;
}
.brand-image a {
	display: block;
	height: 100%;
}
.brand-image img {
	height: 100%;
}
.navigation {
	width: 100%;
	padding-top: 60px;
}
.languages {
	display: flex;
	align-items: center;
	justify-content: right;
	width: 120px;
	height: 30px;
	font-size: 0.875rem;
	font-weight: 500;
	line-height: normal;
}
</style>
